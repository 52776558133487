console.clear();
console.log('test');
window.addEventListener("load", function() {
const form = document.getElementById('my-form');
form.addEventListener("submit", function(e) {
  console.log('yes');
e.preventDefault();
const data = new FormData(form);
const action = e.target.action;
fetch(action, {
method: 'POST',
body: data,
})
.then(() => {
alert("We got it from here, await an email from us!");
})
});
});
